import useDictionaryApi from '~/service/api/useDictionaryApi';
import { uniqBy } from 'lodash-es';
import type { Bank } from '~/models/bank';
import { ProductType } from '~/enums/ProductType';
import { FALLBACK_BANKS_AMOUNT } from '~/service/constants';
import type { SimpleOffer } from '~/models/simulation/simpleSimulation/SimpleOffer';

const TTL = 3 * 60 * 1000;

export function useBanksStore() {
  const { getBanks } = useDictionaryApi();

  const isFetched = useState<Boolean>('isFetched', () => false);
  const pendingBanks = useState<Boolean>('isPending', () => false);
  const banks = useState<Bank[]>('banks', () => []);
  const lastFetched = useState<Date | null>('lastFetched', () => null);
  const numberOfBanks = useState<Partial<Record<ProductType, number>>>(
    'numberOfBanks', () => ({
      [ProductType.MORTGAGE]: FALLBACK_BANKS_AMOUNT,
      [ProductType.CASH]: FALLBACK_BANKS_AMOUNT,
      [ProductType.SME]: FALLBACK_BANKS_AMOUNT,
    })
  );
  const products = useState('products', () => ({
    sme: [],
    cash: [],
    mortgage: [],
  }));

  const fetchData = async () => {
    pendingBanks.value = true;
    const response = await getBanks();
    const banksData = response.data.value;
    if (!banksData) {
        pendingBanks.value = false;
        return;
    }
    const uniqBanks = uniqBy(Object.values(banksData).flat(), bank => bank.symbol);
    products.value = banksData;

    numberOfBanks.value[ProductType.MORTGAGE] = banksData.mortgage.length;
    numberOfBanks.value[ProductType.CASH] = banksData.cash.length;
    numberOfBanks.value[ProductType.SME] = banksData.sme.length;

    banks.value = uniqBanks;
    pendingBanks.value = false;
    isFetched.value = true;
    lastFetched.value = new Date();
  };

  const checkAndFetchData = () => {
    const now = new Date();
    if (!isFetched.value || !lastFetched.value || now - new Date(lastFetched.value) > TTL) {
      fetchData();
    }
  };

  checkAndFetchData();

  const getOfferBank = (offer: SimpleOffer): Bank | null => {
    return banks.value.find(bank => offer.bankSymbol === bank.symbol || offer.bankName === bank.name) || null;
  };

  return {
    getOfferBank,
    banks: banks.value,
    products: products.value,
    numberOfBanks: numberOfBanks.value,
    fetchBanks: checkAndFetchData,
  };
}
